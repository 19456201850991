<template>
  <div class="mb-4 md:mb-10">
    <PageHeader title="Franchise" :full="true">
      <template #left>
        <Btn rounded link class="hidden md:inline-flex text-md" :action="reload">
          <icon icon="arrows-rotate" v-tooltip="'Reload table...'" />
        </Btn>
      </template>
      <template #right>
        <IconField iconPosition="left">
          <InputIcon>
            <icon icon="search" class="text-surface-800" />
          </InputIcon>
          <InputText
            v-model="searchPhrase"
            placeholder="Search companies..."
            size="large"
            type="text"
          />
        </IconField>
      </template>
    </PageHeader>

    <div class="flex flex-row flex-wrap gap-5">
      <div
        v-for="(franchisee, index) in franchisees"
        :key="index"
        class="w-full md:w-80 md:max-w-80 flex flex-col justify-between border rounded p-6 min-h-48"
      >
        <div class="flex items-center justify-center w-full aspect-square">
          <img :src="getFranchiseeLogo(franchisee)" @error="imgError = true" class="rounded" />
        </div>
        <div class="flex flex-col w-full">
          <h2>{{ franchisee.company_name_short || franchisee.company_name }}</h2>
          <p>
            {{ franchisee.company_address || 'N/A' }}, {{ franchisee.company_city || 'N/A' }},
            {{ franchisee.company_prov || 'N/A' }}
          </p>
          <p>{{ franchisee.owner_fname }} {{ franchisee.owner_lname }}</p>
          <p>{{ franchisee.user_count }} employees</p>
        </div>
        <div class="flex flex-row gap-3 w-full mt-6">
          <btn size="lg" severity="tertiary" class="w-full" @click="goMetrics()"> Metrics </btn>
          <btn
            v-if="getScopeRoute(franchisee.company_id)"
            size="lg"
            severity="primary-black"
            class="w-full"
            @click="goCompany(franchisee.company_id)"
          >
            Login
          </btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import PageHeader from '@/components/layout/page/PageHeader.vue'
import InputIcon from 'primevue/inputicon'
import IconField from 'primevue/iconfield'
import InputText from 'primevue/inputtext'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref, onMounted } from 'vue'

const store = useStore()
const router = useRouter()
const franchisees = ref([])
const searchPhrase = ref()

async function getFranchisees() {
  const { payload } = await store.dispatch('ajax', {
    path: 'franchisor/getFranchisees'
  })
  franchisees.value = payload
}

function getFranchiseeLogo(franchisee) {
  return c.link(`file/pic/${franchisee.company_logo_file_id}`)
}

async function goCompany(id) {
  const scopeRoute = getScopeRoute(id)
  if (scopeRoute) {
    await store.dispatch('to', '/home')
    router.push({ name: 'Pipeline', params: { scopeRoute } })
  } else {
    store.dispatch('alert', {
      error: true,
      message: 'You do not have permission to enter that company.'
    })
  }
}

function getScopeRoute(id) {
  const companies = store.state.session.scopableObjects.company
  const company = companies.filter((c) => c.company_id === id)
  if (company.length) return company[0].route
  return null
}

function goMetrics() {
  router.push({ name: 'Franchise Metrics' })
}

function reload() {
  getFranchisees()
}

onMounted(() => {
  reload()
})
</script>
